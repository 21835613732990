import { Box, styled } from "@mui/material";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";

import AdminSummariesTab from "~/features/andon/AdminSummariesTab";

import { selectFetchWorkstationsInterval } from "~/features/andon/andon.slice";
import AndonSettings from "~/features/settings/AndonSettings";
import SettingsPanel from "~/features/settings/SettingsPanel";
import { SystemStatus } from "~/features/status/SystemStatus";
import { GridView } from "~/features/status/gridView/GridView";
import { setSelectedAutostoreGridId } from "~/features/status/status.slice";
import { selectUserIsWorkstationConfigurer } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";

import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

const ServiceSupportContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(3),
  alignItems: "flex-start",
  padding: theme.spacing(4),
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr"
  },
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none"
}));

const ServiceSupport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const clientConfig = useAppSelector(selectClientConfig);
  const isWorkstationConfigurer = useAppSelector(
    selectUserIsWorkstationConfigurer
  );

  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const fetchWorkstationsInterval = useAppSelector(
    selectFetchWorkstationsInterval
  );

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  useGetWorkstationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skipPollingIfUnfocused: true,
    pollingInterval: fetchWorkstationsInterval || 0
  });

  const { an_andonFeaturesEnabled } = clientConfig;
  const pickingConfigurations = usersFulfillmentCenter?.pickingConfigurations;

  useEffect(() => {
    if (!selectedAutostoreGridId && autostoreGrids?.length) {
      dispatch(
        setSelectedAutostoreGridId(
          workstationAutostoreGridId || autostoreGrids[0].autostoreGridId
        )
      );
    }
  }, [
    autostoreGrids,
    workstationAutostoreGridId,
    selectedAutostoreGridId,
    dispatch
  ]);

  return (
    <ServiceSupportContainer>
      {pickingConfigurations?.includes("Autostore") && (
        <Box
          sx={{
            gridColumn: "span 2"
          }}
        >
          <SettingsPanel panelTitle={t("Grid View")}>
            <GridView />
          </SettingsPanel>
        </Box>
      )}
      {pickingConfigurations?.includes("Autostore") && (
        <SettingsPanel panelTitle={t("system status")}>
          <SystemStatus />
        </SettingsPanel>
      )}
      {an_andonFeaturesEnabled && isWorkstationConfigurer && (
        <SettingsPanel panelTitle={t("Andon Settings")}>
          <AndonSettings />
        </SettingsPanel>
      )}
      {an_andonFeaturesEnabled && (
        <Box
          sx={{
            gridColumn: "span 2"
          }}
        >
          <SettingsPanel panelTitle={t("admin summaries")}>
            <AdminSummariesTab />
          </SettingsPanel>
        </Box>
      )}
    </ServiceSupportContainer>
  );
};

export default ServiceSupport;
