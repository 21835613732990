import {
  Stack,
  Grid,
  Paper,
  Typography as MaterialTypography,
  Input,
  InputLabel
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useDebounce } from "~/hooks/useDebounce";
import { useNavbar } from "~/hooks/useNavbar";
import { useToast } from "~/hooks/useToast";
import { useView } from "~/hooks/useView";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import {
  useEditCleaningScheduleMutation,
  useGetAutostoreGridQuery,
  useGetBinCleaningReportQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

const Typography = (props: PropsWithChildren<object>) => {
  return (
    <MaterialTypography variant="h6" sx={{ mx: 1, color: "text.primary" }}>
      {props.children}
    </MaterialTypography>
  );
};

const Title = (props: PropsWithChildren<object>) => {
  return (
    <MaterialTypography variant="h5" sx={{ m: 1, color: "text.primary" }}>
      {props.children}
    </MaterialTypography>
  );
};

type CleaningBinScheduleInheritedProps = {
  viewTitle?: string;
};
type CleaningBinScheduleProps = CleaningBinScheduleInheritedProps;

export default function CleaningBinSchedule(props: CleaningBinScheduleProps) {
  const { viewTitle } = props;

  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  useView({ permanentSidenav: true });
  useNavbar({ viewTitle });
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );

  const { data: selectedAutostoreGrid, isFetching } = useGetAutostoreGridQuery(
    selectedAutostoreGridId ?? skipToken
  );

  const { data: binCleaningReport } = useGetBinCleaningReportQuery(
    selectedAutostoreGridId ?? skipToken
  );
  const { dailyQuota, remainingBinsToClean, totalBins } =
    binCleaningReport ?? {};

  const [editCleaningSchedule] = useEditCleaningScheduleMutation();

  const [scheduleState, setScheduleState] = useState({
    cleaningPeriod: selectedAutostoreGrid?.cleaningCadenceInDays ?? null
  });

  useEffect(() => {
    if (!!selectedAutostoreGrid && !isFetching) {
      setScheduleState({
        cleaningPeriod: selectedAutostoreGrid.cleaningCadenceInDays ?? null
      });
    }
  }, [isFetching, selectedAutostoreGrid]);

  const updateGrid = useCallback(async () => {
    if (!scheduleState || isFetching) return;

    const { cleaningPeriod } = scheduleState;

    const shouldUpdate =
      selectedAutostoreGrid?.autostoreGridId &&
      cleaningPeriod !== (selectedAutostoreGrid.cleaningCadenceInDays ?? null);

    if (shouldUpdate) {
      try {
        await editCleaningSchedule({
          autostoreGridId: selectedAutostoreGrid?.autostoreGridId,
          minCleaningTasks: null,
          maxCleaningTasks: null,
          cleaningCadenceInDays: cleaningPeriod,
          maxBinFlaggedInventoryMoveTaskGroupSize: null
        }).unwrap();
        successToast("Successfully updated the cleaning schedule");
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    }
  }, [
    scheduleState,
    selectedAutostoreGrid,
    isFetching,
    editCleaningSchedule,
    errorToast,
    successToast
  ]);

  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
  useDebounce(updateGrid, 1000);

  const paperStyle = {
    border: "1px solid black",
    borderRadius: "10",
    padding: "25px"
  };
  const gridStyle = {
    marginTop: 0,
    width: "100%",
    marginLeft: "10px",
    paddingRight: "10px",
    height: "100%",
    alignItems: "flex-start"
  };

  if (!selectedAutostoreGrid) return <></>;
  return (
    <Stack
      direction="column"
      sx={{ gridColumn: "span 2", placeSelf: "center stretch" }}
    >
      <Paper style={paperStyle}>
        <Title>{t("bin cleaning schedule")}</Title>
        <Grid container spacing={2} style={gridStyle}>
          <Grid item xs={12} sm={6}>
            <Typography>
              {`${t("grid")}${selectedAutostoreGrid.autostoreGridName}`}
            </Typography>
            <Typography>
              {`${t("grid temp zone")}${selectedAutostoreGrid.temperatureZone}`}
            </Typography>
            <InputLabel htmlFor="cleaning-period">
              <Typography>{t("cleaning period")}</Typography>
            </InputLabel>
            <Input
              sx={{ m: 1 }}
              id="cleaning-period"
              type="number"
              inputProps={{ min: 0 }}
              value={scheduleState.cleaningPeriod || ""}
              onChange={(e) => {
                setScheduleState({
                  ...scheduleState,
                  cleaningPeriod: e.target.value ? Number(e.target.value) : null
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              {t("bins in grid")}
              {typeof totalBins === "number" ? totalBins : ""}
            </Typography>
            <Typography>
              {t("bins remaining to clean daily")}
              {typeof dailyQuota === "number" ? dailyQuota : ""}
            </Typography>
            <Typography>
              {t("bins remaining to clean total")}
              {typeof remainingBinsToClean === "number"
                ? remainingBinsToClean
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
}
