import { Button, DialogTitle, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useToast } from "~/hooks/useToast";
import { useBarcodeScanner } from "~/lib/barCodeScan";
import { getBarcodeValue } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { GetPortResponse } from "~/redux/actions";

import { usePostPutawayBinNotEmptyMutation } from "~/redux/warehouse/inventory.hooks";

type BinNotEmptyModalProps = {
  fetchNextEmptyBin?: (
    args?:
      | {
          autostoreGridId?: string | undefined;
          portId?: number | undefined;
        }
      | undefined
  ) => Promise<void>;
  fetchPortStatus?: (
    args?:
      | {
          portId?: number | undefined;
          autostoreGridId?: string | undefined;
        }
      | undefined
  ) => Promise<void | GetPortResponse>;
  binId: Guid | null | undefined;
  portId?: number | undefined;
  setIsBinNotEmptyPanelOpen: Dispatch<SetStateAction<boolean>>;
  handleSetBinNotEmpty?: (productIdentifier: string) => void;
  onSuccess?: () => void;
};

export function BinNotEmptyModal(props: BinNotEmptyModalProps) {
  const { portId, binId, onSuccess } = props;
  const { errorToast, successToast } = useToast();
  const { t } = useTranslation();
  const [scannedBarcode, setScannedBarcode] = useState<string>("");
  const [productInBin, setProductInBin] = useState<boolean>(false);

  const [setBinNotEmpty, { isLoading, error, isSuccess, isUninitialized }] =
    usePostPutawayBinNotEmptyMutation();

  useEffect(() => {
    if (isSuccess) {
      successToast(`Hold placed on UPC ${scannedBarcode}`);
    } else if (error) {
      errorToast(getMessageFromRtkError(error));
    }
  }, [error, isSuccess, errorToast, scannedBarcode, successToast]);

  const newSelectedCompartment = useAppSelector(
    (state) => state.autostorePutaway.newSelectedCompartment
  );

  const siteWorkstation = useAppSelector(
    (state) => state.workstations.siteWorkstation
  );

  const submitManualEnteredBarcode = (ev: KeyboardEvent) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      setProductInBin(true);
    }
  };

  useBarcodeScanner({
    findScanMatch: (initialBuffer: string) => {
      const barcodeValue: string = getBarcodeValue(initialBuffer);
      setScannedBarcode(barcodeValue);
      setProductInBin(true);
    },
    processScanMatch: () => null
  });

  const placeBinNotEmptyFetchNextBin = async (upc: string, exit: boolean) => {
    if (portId && binId && newSelectedCompartment != null && siteWorkstation) {
      const { autostoreGridId, id: workstationId } = siteWorkstation;
      try {
        await setBinNotEmpty({
          productIdentifier: upc,
          binId,
          portId,
          gridId: autostoreGridId,
          workstationId
        }).unwrap();
        if (props.fetchNextEmptyBin) {
          await props.fetchNextEmptyBin({
            portId,
            autostoreGridId
          });
        }
        if (props.fetchPortStatus) {
          await props.fetchPortStatus({ portId, autostoreGridId });
        }
      } catch (e: unknown) {
        if ((e as FetchBaseQueryError).status === 404 && !exit) {
          // if initial attempt to set bin not empty fails, try the unprocessed scanned barcode value
          await placeBinNotEmptyFetchNextBin(scannedBarcode, true);
        }
      }
    }
  };

  const handleConfirm = async () => {
    if (productInBin) {
      const barcode = getBarcodeValue(scannedBarcode);
      if (props.handleSetBinNotEmpty) props.handleSetBinNotEmpty(barcode);
      else {
        await placeBinNotEmptyFetchNextBin(barcode, false);
      }

      props.setIsBinNotEmptyPanelOpen(false);
      if (onSuccess) onSuccess();
    }
  };

  return (
    <Grid
      data-testid="bin-not-empty-modal-content"
      container
      item
      xs={12}
      direction="column"
      wrap="nowrap"
    >
      <>
        <Grid item>
          <Card>
            <DialogTitle>{t("please scan the product in the bin")}</DialogTitle>
            <Box padding={4}>
              <Grid item container xs={12}>
                <TextField
                  id="scannedBarcode"
                  type="string"
                  label="UPC"
                  fullWidth
                  value={scannedBarcode}
                  focused={!!scannedBarcode}
                  inputProps={{
                    sx: {
                      fontSize: "40px",
                      padding: "35px 10px 35px 18px",
                      textAlign: "center"
                    }
                  }}
                  onChange={(event) => setScannedBarcode(event.target.value)}
                  onKeyDown={submitManualEnteredBarcode}
                />
              </Grid>
              {productInBin && (
                <Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    margin="5px"
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
                      {t("confirm when the product is in the bin")}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    margin="5px"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleConfirm}
                      disabled={!isUninitialized || isLoading}
                    >
                      {t("confirm")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Card>
        </Grid>
      </>
    </Grid>
  );
}
