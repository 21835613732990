import Button from "@locaisolutions/button";
import { Bin20Px, BookmarkFill20Px, Zone20Px } from "@locaisolutions/icons";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Box,
  Container,
  Dialog,
  Stack,
  SvgIcon,
  styled
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ErrorPanel } from "~/components/ErrorPanel";
import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";
import ScanningIndicator, {
  useScanIndicator
} from "~/components/ScanningIndicator";
import MultiPort from "~/components/autostore/autostoreBin/MultiPort";
import UniversalProductCard, {
  PickInfoIsLoading
} from "~/components/productCard/UniversalProductCard";
import useFlag from "~/config/flags";
import { useDebounce, debounce } from "~/hooks/useDebounce";
import { useInactivityResetTimer } from "~/hooks/useInactivityResetTimer";
import { useNavbar } from "~/hooks/useNavbar";
import usePortStatus from "~/hooks/usePortStatus";
import usePrevious from "~/hooks/usePrevProps";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useToast } from "~/hooks/useToast";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import {
  checkIsExpiration,
  getBarcodeValue,
  fetchAlgoliaSearch
} from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { isAbortedRequest } from "~/lib/shared";
import { useGridV2Subscription } from "~/lib/signalr";

import usePromiseInterval from "~/lib/usePromiseIntervalEffect";

import {
  nextEmptyBin,
  fetchPutawayTasks,
  fetchPortStatus,
  resetPortBinData
} from "~/redux/actions";
import { setCurrentEmptyBin } from "~/redux/actions/autostore";
import {
  clearSelectedVariant,
  clearInventoryMessage
} from "~/redux/actions/inventory";
import {
  clearErrorMessage,
  clearSuccessMessage
} from "~/redux/actions/putaway";
import { StoreState } from "~/redux/reducers";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import {
  selectIsInventoryAtPort,
  selectIsInventoryDateValid,
  selectSelectedRow
} from "~/redux/selectors/autostorePutawaySelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { useLazySuggestBinConfigurationQuery } from "~/redux/warehouse/cubing.hooks";
import {
  MeasuredValueDto,
  AutostoreEvent,
  NextEmptyBinResponse,
  PutAwayTaskSummaryDto,
  SuggestBinConfigurationResponse
} from "~/types/api";

import { AutostorePutawaySearch } from "./AutostorePutawaySearch";
import { ConfirmInductionButton } from "./ConfirmInductionButton";
import InventoryDateField from "./InventoryDateField";
import PutawayTasksTable from "./PutawayTasksTable";
import { QuantityField } from "./QuantityField";
import {
  selectRow,
  selectCompartment,
  setChangedQuantity,
  setInventoryDate,
  setSearchData,
  setIsPutawayTaskTableRefreshed,
  setSuggestBinConfigurationError,
  setSuggestBinConfigurationIsLoading,
  setPortPollingActive,
  setClosePortBtnDisabled,
  selectNewCompartment,
  reset,
  setOnLeaveInputTextShown
} from "./autostorePutaway.slice";
import { AdjustQuantityModal } from "./modals/AdjustQuantityModal";
import BinNotEmptyCompartment from "./modals/BinNotEmptyCompartment";
import { BinNotEmptyModal } from "./modals/BinNotEmptyModal";
import ChangeSuggestedBinModal from "./modals/ChangeSuggestedBinModal";
import DecantRateModal from "./modals/DecantRateModal";
import FlagBinModal from "./modals/FlagBinModal";
import InventoryHoldModal from "./modals/InventoryHoldModal";
import { useStartInduction } from "./useStartInduction";

const PickInfoFieldsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 210px 1fr;
  gap: 16px;
  align-items: center;
`;

const mapStateToProps = (state: StoreState) => ({
  putawayTasks: state.autostore.putawayTasks,
  putawayTasksLoading: state.autostore.putawayTasksLoading,
  /** response from nextEmptyBin call */
  nextEmptyBinByPort: state.autostore.nextEmptyBinByPort,
  selectedBinConfigurations: state.autostore.selectedBinConfigurations,
  successPutawayCompletion: state.putaway.successMessage,
  errorPutawayCompletion: state.putaway.error,
  portStateByPort: state.autostore.portStateByPort,
  siteAllPortIds: state.workstations.siteAllPortIds,
  currentSelectedBin: state.autostore.currentEmptyBin
});

const connector = connect(mapStateToProps, {
  fetchPutawayTasks,
  clearErrorMessage,
  clearSuccessMessage,
  clearInventoryMessage,
  nextEmptyBin,
  fetchPortStatus,
  clearSelectedVariant,
  resetPortBinData,
  setCurrentEmptyBin
});

type Props = ConnectedProps<typeof connector> & { viewTitle: string };

export function AutostorePutaway(props: Props) {
  const {
    putawayTasks,
    putawayTasksLoading,
    nextEmptyBinByPort,
    successPutawayCompletion,
    errorPutawayCompletion,
    portStateByPort,
    siteAllPortIds,
    viewTitle,
    currentSelectedBin
  } = props;
  const nextEmptyBins = Object.values(nextEmptyBinByPort);
  const dispatch = useAppDispatch();

  const currentSelectedPortId = currentSelectedBin?.openBinResponse.portId;
  const currentSelectedBinId = currentSelectedBin?.openBinResponse.binId;
  const selectedRowId = useAppSelector(
    (state) => state.autostorePutaway.selectedRowId
  );
  const selectedCompartment = useAppSelector(
    (state) => state.autostorePutaway.selectedCompartment
  );
  const newSelectedCompartment = useAppSelector(
    (state) => state.autostorePutaway.newSelectedCompartment
  );
  const changedQuantity = useAppSelector(
    (state) => state.autostorePutaway.changedQuantity
  );
  const inventoryDate = useAppSelector(
    (state) => state.autostorePutaway.inventoryDate
  );
  const searchData = useAppSelector(
    (state) => state.autostorePutaway.searchData
  );
  const isPutawayTaskTableRefreshed = useAppSelector(
    (state) => state.autostorePutaway.isPutawayTaskTableRefreshed
  );
  const portPollingActive = useAppSelector(
    (state) => state.autostorePutaway.portPollingActive
  );
  const selectedRow = useAppSelector(selectSelectedRow);
  const rowHasDate =
    selectedRow?.expirationDate || selectedRow?.manufactureDate;
  const hasDateBeenRemoved = !inventoryDate && rowHasDate;
  const isInventoryDateValid =
    useAppSelector(selectIsInventoryDateValid) && !hasDateBeenRemoved;

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const clientId = useAppSelector(selectUsersClientId);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const binFlaggingEnabled = fulfillmentCenter?.binFlaggingEnabled;
  const {
    inv_inventoryDateLabel,
    putaway_multipleSearchTerms,
    putaway_showDecantRate
  } = useAppSelector(selectClientConfig);
  const { data: selectedAutostoreGrid } = useGetAutostoreGridQuery(
    siteWorkstation?.autostoreGridId ?? skipToken
  );

  const [
    suggestBinConfigurationErrorMessage,
    setSuggestBinConfigurationErrorMessage
  ] = useState<string | null>(null);
  const { errorToast } = useToast();
  const [
    suggestBinConfiguration,
    {
      data: suggestedBin,
      isLoading: suggestBinConfigurationIsLoading,
      error: suggestBinConfigurationError
    }
  ] = useLazySuggestBinConfigurationQuery();

  useEffect(() => {
    dispatch(setSuggestBinConfigurationError(!!suggestBinConfigurationError));
    dispatch(
      setSuggestBinConfigurationIsLoading(suggestBinConfigurationIsLoading)
    );
  }, [
    dispatch,
    suggestBinConfigurationError,
    suggestBinConfigurationIsLoading
  ]);

  // Hooks
  const { t } = useTranslation();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  useKeyDownHandler();
  const { areAllPortsReady, areSomePortsReady, firstPort } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedPortId
  );
  const [scanState, setScanState] = useScanIndicator();

  /** Bookstore has a specific Bin Not Empty flow. Other clients will show the
   * Add Inventory dialog for bin not empty.
   */
  const enableBinNotEmptyModal = useFlag().enableBinNotEmptyModal;

  const prevSuggestedBin = usePrevious(suggestedBin);
  //if at least one bin is called to the port, then call suggest-bin-configuration
  const hasNextEmptyBinCallCompleted =
    Object.keys(nextEmptyBinByPort).length >= 1;

  const isPlaceHoldEnabled =
    useAppSelector(selectIsInventoryAtPort) &&
    hasNextEmptyBinCallCompleted &&
    siteWorkstation;

  // Local State - Modals
  const [changeSuggestedBinModalOpen, setChangeSuggestedBinModalOpen] =
    useState(false);
  const [
    isBinNotEmptyCompartmentPanelOpen,
    setIsBinNotEmptyCompartmentPanelOpen
  ] = useState(false);
  const [isBinNotEmptyPanelOpen, setIsBinNotEmptyPanelOpen] = useState(false);
  const [isInventoryHoldModalOpen, setIsInventoryHoldModalOpen] =
    useState(false);
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);
  const [isDecantRateModalOpen, setIsDecantRateModalOpen] =
    useState<boolean>(false);
  const [isUnderReceivedModalOpen, setIsUnderReceivedModalOpen] =
    useState(false);
  const [isOverReceivedModalOpen, setIsOverReceivedModalOpen] = useState(false);
  // Using useRef as useState does not update in time when referencing the value
  // Todo: remove usage of ref. Will need to refactor the quantity change logic.
  const hasBinBeenChanged = useRef(false);

  // Local State
  const [newSelectedBin, setNewSelectedBin] =
    useState<NextEmptyBinResponse | null>(null);
  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);
  const [lastScannedBarcode, setLastScannedBarcode] = useState<string | null>(
    null
  );
  const [isBinSuggested, setIsBinSuggested] = useState(false);

  const hasSuggestedBinChanged =
    suggestedBin && suggestedBin !== prevSuggestedBin;
  const pageLimit = 7;
  const shouldShowActionButtons =
    putawayTasksLoading || putawayTasks.length >= 0;

  const onInactivityModalClose = useCallback(() => {
    setIsBinNotEmptyCompartmentPanelOpen(false);
    dispatch(reset());
    dispatch(setClosePortBtnDisabled(true));
    dispatch(setPortPollingActive(false));
    if (shouldListenToGridEvents) {
      setBinAtPortSeconds(0);
    }
  }, [dispatch, shouldListenToGridEvents]);

  const { restartInactivityTimer } = useInactivityResetTimer({
    onInactivityModalClose
  });

  const handleBarcodeScan = (barcode: string) => {
    // if client expects to scan PO and product for one search (search within a search)
    if (putaway_multipleSearchTerms) {
      // if there are already 2 values in the search or the current search has no results, clear and set to new barcode value
      const newBarcodeArray =
        searchData.scannedBarcodes.length > 1 || putawayTasks.length === 0
          ? [barcode]
          : [...searchData.scannedBarcodes, barcode];
      dispatch(
        setSearchData({
          scannedBarcodes: newBarcodeArray,
          offset: 1
        })
      );
    } else {
      dispatch(
        setSearchData({
          scannedBarcodes: [barcode],
          offset: 1
        })
      );
    }
    dispatch(selectNewCompartment(null));
  };

  useBarcodeScanner<boolean>({
    findScanMatch: (buffer: string) => {
      setLastScannedBarcode(buffer);
      const barcodeValue = getBarcodeValue(buffer);
      handleBarcodeScan(barcodeValue);
      setScanState("success");
      restartInactivityTimer();
      return true;
    },
    processScanMatch: () => null,
    disabled: isBinNotEmptyPanelOpen
  });

  // Clear port and bin state on first render
  useEffect(() => {
    props.resetPortBinData();
    dispatch(
      setSearchData({
        scannedBarcodes: [],
        offset: 1
      })
    );
    setLastScannedBarcode(null);
    dispatch(selectRow(null));
    dispatch(setIsPutawayTaskTableRefreshed(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the current bin (the blue outline around the bin)
  useEffect(() => {
    // if suggested bin, set to currentSelectedBin to suggested bin
    if (
      (hasSuggestedBinChanged || isBinSuggested) &&
      nextEmptyBinByPort &&
      selectedRow
    ) {
      const sortBinsByNumberOfCompartments = (
        bins: (NextEmptyBinResponse | null)[]
      ): (NextEmptyBinResponse | null)[] =>
        bins.sort(
          (a, b) =>
            (a?.autostoreBinConfiguration?.numberOfCompartments || 1) -
            (b?.autostoreBinConfiguration?.numberOfCompartments || 1)
        );
      // use the suggested bin.portId if provided, otherwise default to the largest bin (bin with lowest number of compartments)
      const portId =
        suggestedBin?.portId ||
        sortBinsByNumberOfCompartments(nextEmptyBins)[0]?.openBinResponse
          .portId;
      props.setCurrentEmptyBin(portId ? nextEmptyBinByPort[portId] : null);
    } else if (
      // if no suggested bin and we don't care about suggested bin, set to first bin
      !suggestedBin &&
      !putaway_showDecantRate &&
      firstPort &&
      nextEmptyBinByPort
    ) {
      props.setCurrentEmptyBin(
        nextEmptyBinByPort[firstPort.getPortResponse.portId]
      );
    } else if (!selectedRow) {
      // after putaway is complete, row will be unselected, unselect bin/port
      props.setCurrentEmptyBin(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextEmptyBinByPort, hasSuggestedBinChanged, selectedRow, isBinSuggested]);

  const useBackendForAlgoliaSearch = useFlag().useBackendForAlgoliaSearch;

  // After scanning barcode, if there are no putaway tasks returned check algolia. If product
  // doesn't exist display an error.
  useEffect(() => {
    if (
      putawayTasks.length === 0 &&
      searchData.scannedBarcodes.length &&
      clientId
    ) {
      const algoliaSearchFunc = async () => {
        const hits = await fetchAlgoliaSearch(
          useBackendForAlgoliaSearch,
          clientId,
          searchData.scannedBarcodes
        );
        if (!hits.length) {
          errorToast(t("scanned product does not exist"));
        }
      };
      void algoliaSearchFunc();
    }
  }, [putawayTasks.length, searchData, clientId, errorToast, t]);

  const handleFetchPortStatus = async (portId?: number) => {
    await props.fetchPortStatus({ portId });
  };

  // Port polling: if log publisher enabled, poll port and log publisher state
  const waitInterval = 7;
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > waitInterval && siteWorkstation) {
        await Promise.all(
          siteWorkstation.ports.map((port) => {
            if (!portStateByPort[port.portId]?.getPortResponse.isReady)
              return handleFetchPortStatus(port.portId);

            return Promise.resolve();
          })
        );
      }
    },
    1000,
    !areAllPortsReady && shouldListenToGridEvents
  );

  // If port polling active but log publisher not active for grid, poll port every half second
  usePromiseInterval(
    async () => {
      await Promise.all(
        (siteWorkstation?.ports ?? []).map((port) =>
          handleFetchPortStatus(port.portId)
        )
      );
    },
    500,
    portPollingActive && !shouldListenToGridEvents
  );

  // Reset binAtPortSeconds, when bin arrives at port
  useEffect(() => {
    if (areAllPortsReady && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllPortsReady]);

  // Stop polling when bin arrives
  useEffect(() => {
    if (!shouldListenToGridEvents && areAllPortsReady) {
      dispatch(setPortPollingActive(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portStateByPort]);

  const debouncedSearchData = useDebounce(searchData, 200);

  // copy/paste this into button
  const handlePutawayTaskFetch = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    props.fetchPutawayTasks({
      search: searchData.searchInputValue,
      searches: searchData.scannedBarcodes,
      binType: ["induction", "receiving"],
      limit: pageLimit,
      offset: (searchData.offset - 1) * pageLimit,
      temperatureZone: selectedAutostoreGrid?.temperatureZone
        ? [selectedAutostoreGrid.temperatureZone.toLowerCase()]
        : [],
      searchSource: searchData.scannedBarcodes.length ? "scanner" : undefined,
      workstationId: siteWorkstation?.id
    });
  };

  // Search tasks on barcode scan or input entry
  useEffect(() => {
    handlePutawayTaskFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchData]);

  const handleSetSuggestedCompartment = (
    suggestedBinParam?: SuggestBinConfigurationResponse | null
  ) => {
    const currentSuggestedBin = suggestedBinParam || suggestedBin;

    if (
      (currentSelectedBin && newSelectedCompartment === null) ||
      currentSelectedBin === null ||
      suggestedBinParam
    ) {
      const suggestedCompartment =
        currentSuggestedBin?.binNumber &&
        currentSuggestedBin.binCompartmentNumber !== undefined
          ? currentSuggestedBin.binCompartmentNumber
          : currentSelectedBin?.suggestedPutawayCompartment;

      dispatch(selectCompartment((suggestedCompartment || 1) - 1));
      dispatch(selectNewCompartment((suggestedCompartment || 1) - 1));
    }
  };

  // Set suggested compartment
  useEffect(() => {
    if (!currentSelectedBin) {
      return;
    }
    handleSetSuggestedCompartment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedBinId]);

  const abortRef = useRef(() => {});
  useEffect(() => () => abortRef.current(), []);

  const handleGetSuggestedBin = async (
    row: PutAwayTaskSummaryDto,
    quantity?: number,
    binId?: number
  ) => {
    if (
      putaway_showDecantRate &&
      siteWorkstation &&
      (quantity || row.decantingRate?.value)
    ) {
      let decantRateValue = quantity;
      if (!decantRateValue) {
        if (!row.decantingRate?.value) {
          return;
        }
        decantRateValue =
          row.decantingRate.value > row.remaining.value
            ? row.remaining.value
            : row.decantingRate.value;
      }
      const decantRate: MeasuredValueDto = {
        units: row.decantingRate?.units || row.quantity.units,
        value: decantRateValue
      };
      try {
        abortRef.current();

        const query = suggestBinConfiguration({
          workstationId: siteWorkstation.id,
          count: decantRate,
          variantId: row.product.variantId,
          binId,
          useCubing: !hasBinBeenChanged.current
        });

        abortRef.current = () => query.abort();

        const result = await query.unwrap();

        if (result.portId && nextEmptyBinByPort[result.portId])
          props.setCurrentEmptyBin(nextEmptyBinByPort[result.portId]);

        handleSetSuggestedCompartment(result);

        setSuggestBinConfigurationErrorMessage(null);
        setIsBinSuggested(true);
      } catch (err) {
        if (isAbortedRequest(err)) return;
        //This shouldn't be a permanent solution; we need to come up with a solution involving error codes, for instance
        if (
          getMessageFromRtkError(err).includes(
            "This workstation cannot hold the current quantity"
          )
        ) {
          setIsBinSuggested(false);
          setSuggestBinConfigurationErrorMessage(getMessageFromRtkError(err));
        }
        errorToast(getMessageFromRtkError(err));
      }
    }
  };

  const onFlagBinOrBinNotEmptySuccess = () => {
    if (hasNextEmptyBinCallCompleted && selectedRow) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetSuggestedBin(selectedRow);
    }
  };

  // For clients that use decant rate, make the '/suggest-bin-configuration' call
  useEffect(() => {
    if (hasNextEmptyBinCallCompleted && selectedRow) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetSuggestedBin(selectedRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextEmptyBinCallCompleted]);

  // Passing 'row' into the debounced function is necessary to avoid outdated values since when the
  // debounce is created it will only have access to the values at the time it was created
  const debouncedHandleGetSuggestedBin = debounce(
    (row: PutAwayTaskSummaryDto, quantity: number, binId?: number) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetSuggestedBin(row, quantity, binId);
    },
    500
  );

  const debouncedHandleGetSuggestedBinCallback = useCallback(
    (row: PutAwayTaskSummaryDto, quantity: number, binId?: number) =>
      debouncedHandleGetSuggestedBin(row, quantity, binId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChangeQuantity = (
    row: PutAwayTaskSummaryDto,
    quantity: number
  ) => {
    dispatch(setChangedQuantity(quantity));

    void debouncedHandleGetSuggestedBinCallback(
      row,
      quantity,
      // Providing the binId tells the endpoint we have changed the bin and
      // it should pick the ideal compartment for this bin
      hasBinBeenChanged.current ? currentSelectedBinId : undefined
    );

    dispatch(selectNewCompartment(null));
  };

  // If there's only one putaway default to that task
  useEffect(() => {
    if (putawayTasks.length === 1) {
      dispatch(selectRow(putawayTasks[0].putAwayTaskId));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      handleGetSuggestedBin(putawayTasks[0]);
    } else {
      dispatch(selectRow(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayTasks]);

  // Set inventory date to display
  useEffect(() => {
    if (checkIsExpiration(inv_inventoryDateLabel)) {
      dispatch(
        setInventoryDate(
          selectedRow?.expirationDate ? selectedRow.expirationDate : null
        )
      );
    } else {
      dispatch(
        setInventoryDate(
          selectedRow?.manufactureDate ? selectedRow.manufactureDate : null
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  // Enable/disable showing text in search bar
  useEffect(() => {
    dispatch(setOnLeaveInputTextShown(!isPutawayTaskTableRefreshed));
  }, [dispatch, isPutawayTaskTableRefreshed]);

  // Clear selected row
  useEffect(() => {
    if (putawayTasks.length === 0 && !putawayTasksLoading) {
      dispatch(selectRow(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayTasks.length]);

  const gridSub = (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") {
      return;
    }

    if (
      data.event.gridId === siteWorkstation?.autostoreGridId &&
      !!data.event.portId &&
      siteAllPortIds.includes(data.event.portId)
    ) {
      // if "binOpened" event is received, trigger port polling
      setBinAtPortSeconds((time) => time + waitInterval);
    }
  };
  useGridV2Subscription(gridSub);

  useStartInduction();

  const handleClickBinNotEmptyButton = () => {
    if (currentSelectedBin) {
      Sentry.captureMessage(
        `Bin not empty was clicked on bin number ${currentSelectedBin.openBinResponse.binId}`,
        "info"
      );
      if (shouldListenToGridEvents) {
        setBinAtPortSeconds(0);
      }
    }
    setIsBinNotEmptyCompartmentPanelOpen(true);
  };

  // Handle setting initial quantity value
  useEffect(() => {
    hasBinBeenChanged.current = false;
    if (!selectedRow?.decantingRate) {
      dispatch(setChangedQuantity(undefined));
    } else {
      const decantRate = selectedRow?.decantingRate?.value || Number.MAX_VALUE;
      const remaining = selectedRow?.remaining.value || 1;
      dispatch(setChangedQuantity(Math.min(decantRate, remaining)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId]);

  const handleClickPlaceInventoryHold = () => {
    setIsInventoryHoldModalOpen((prev) => !prev);
  };

  const handleClickFlagBin = () => {
    setIsFlagBinModalOpen((prev) => !prev);
  };

  const onConfirmChangeSuggestedBin = async (bin: NextEmptyBinResponse) => {
    hasBinBeenChanged.current = true;
    props.setCurrentEmptyBin(bin);
    if (!selectedRow) {
      return errorToast(t("missing required data"));
    }
    try {
      await handleGetSuggestedBin(
        selectedRow,
        changedQuantity,
        bin.openBinResponse.binId
      );
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const handleAdjustQuantityModalSuccess = () => {
    setIsUnderReceivedModalOpen(false);
    setIsOverReceivedModalOpen(false);
    handlePutawayTaskFetch();
    dispatch(selectRow(null));
  };

  const handleSelectRow = async (row: PutAwayTaskSummaryDto) => {
    if (row.putAwayTaskId !== selectedRowId) {
      dispatch(selectNewCompartment(null));
      dispatch(selectRow(row.putAwayTaskId));
      hasBinBeenChanged.current = false;
      if (row.decantingRate?.value && hasNextEmptyBinCallCompleted) {
        await handleGetSuggestedBin(row);
      } else {
        //in handleGetSuggestedBin we are calling handleSetSuggestedCompartment, so we add else part to avoid multiple calls
        handleSetSuggestedCompartment();
      }
    }
  };

  const onUnderReceivedClick = () => {
    setIsUnderReceivedModalOpen(true);
  };

  const onOverReceivedClick = () => {
    setIsOverReceivedModalOpen(true);
  };

  const AutostoreBinView = (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridColumn: "span 2"
      }}
    >
      <MultiPort
        pickQuantity={0}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        currentSelectedBinId={selectedRowId ? currentSelectedBinId : undefined}
        selectedCompartment={selectedCompartment}
        showLoading={!areSomePortsReady}
        enableBinFullWarning
      />

      {/* Bin related buttons (top-right of page) */}
      {shouldShowActionButtons && (
        <Stack justifyContent="space-evenly">
          <Button
            data-testid="change-selected-bin-button"
            variant="contained"
            kind="subtle"
            color="secondary"
            size="large"
            onClick={() => {
              setChangeSuggestedBinModalOpen(true);
            }}
            disabled={!areSomePortsReady || !selectedRowId}
            sx={{ minWidth: "245px", fontWeight: "normal" }}
            startIcon={<SvgIcon viewBox="0 0 20 20" component={Zone20Px} />}
          >
            {t("change selected bin")}
          </Button>
          <Button
            data-testid="bin-not-empty-button"
            variant="contained"
            kind="subtle"
            color="secondary"
            size="large"
            onClick={handleClickBinNotEmptyButton}
            disabled={!areSomePortsReady || isBinNotEmptyCompartmentPanelOpen}
            sx={{ minWidth: "245px", fontWeight: "normal" }}
            startIcon={<SvgIcon viewBox="0 0 20 20" component={Bin20Px} />}
          >
            {t("bin not empty")}
          </Button>
          {binFlaggingEnabled && (
            <Button
              data-testid="flag-bin-button"
              variant="contained"
              kind="subtle"
              color="secondary"
              size="large"
              onClick={handleClickFlagBin}
              disabled={!areSomePortsReady}
              sx={{ minWidth: "245px", fontWeight: "normal" }}
              startIcon={
                <SvgIcon viewBox="0 0 20 20" component={BookmarkFill20Px} />
              }
            >
              {t("flag bin")}
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );

  const { setMenuItems } = useNavbar({
    centerComponent: useMemo(() => <AutostorePutawaySearch />, []),
    viewTitle
  });

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Refresh",
        actionCb: () => {
          dispatch(setIsPutawayTaskTableRefreshed(true));
          setSearchData({
            scannedBarcodes: [],
            offset: 1
          });
          dispatch(selectRow(null));
        }
      }
    ]);
  }, [dispatch, setMenuItems]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "grid",
        gridTemplateRows: "285px minmax(620px, 1fr) 75px",
        gridTemplateColumns: "1fr 0.40fr",
        height: "calc(100vh - 56px)",
        gap: 2,
        pb: 2
      }}
    >
      {siteWorkstation ? (
        AutostoreBinView
      ) : (
        <ErrorPanel
          sx={{ gridColumn: "span 2" }}
          message={t("no autostore port selected")}
          mx={3}
        />
      )}
      {/* Table and PickInfo */}
      <PutawayTasksTable
        inv_inventoryDateLabel={inv_inventoryDateLabel}
        pageLimit={pageLimit}
        putawayTasks={putawayTasks}
        putawayTasksLoading={putawayTasksLoading}
        selectedRow={selectedRow}
        handleSelectRow={handleSelectRow}
      />

      {selectedRow ? (
        <Box>
          <UniversalProductCard
            productName={selectedRow.product.name}
            imageFileName={selectedRow.product.imageFilename}
            sku={selectedRow.product.sku}
            upc={selectedRow.product.upc}
            weight={`${selectedRow.remaining.value} ${selectedRow.remaining.units}`}
            bottomSection={
              <PickInfoFieldsContainer>
                <InventoryDateField
                  inventoryDate={dayjs(inventoryDate)}
                  inv_inventoryDateLabel={inv_inventoryDateLabel}
                  isDateValid={isInventoryDateValid}
                  setInventoryDate={(date) =>
                    dispatch(setInventoryDate(date?.toDate() || null))
                  }
                />
                <QuantityField
                  changedQuantity={changedQuantity}
                  handleChangeQuantity={handleChangeQuantity}
                  selectedRow={selectedRow}
                  isMaxQuantityExceeded={
                    suggestBinConfigurationErrorMessage ? true : false
                  }
                />
              </PickInfoFieldsContainer>
            }
            disableGutters
            hideProductCount
            allUpcs={[selectedRow.product.upc]}
          />

          {suggestedBin?.warning && (
            <Alert variant="filled" severity="warning" sx={{ mt: 3 }}>
              {suggestedBin.warning}
            </Alert>
          )}
        </Box>
      ) : (
        <PickInfoIsLoading marginTop="0" height="579px" disableGutters />
      )}

      {/* Inventory buttons (bottom row) */}
      <Stack justifySelf="end" flexDirection="row" gap={3}>
        {shouldShowActionButtons && (
          <>
            {/* Inventory Problem button */}
            <Button
              data-testid="under-receive-button"
              variant="contained"
              size="large"
              onClick={onUnderReceivedClick}
              disabled={!selectedRow || !siteWorkstation}
              kind="subtle"
              color="secondary"
              sx={{
                fontWeight: "normal"
              }}
            >
              {t("under received")}
            </Button>
            <Button
              data-testid="over-receive-button"
              variant="contained"
              size="large"
              onClick={onOverReceivedClick}
              disabled={!selectedRow || !siteWorkstation}
              kind="subtle"
              color="secondary"
              sx={{
                fontWeight: "normal"
              }}
            >
              {t("over received")}
            </Button>
            {/* Inventory Exceptions button (aka Problem Solve)  */}
            <Button
              data-testid="inventory-hold-button"
              variant="contained"
              size="large"
              startIcon={<ErrorOutlineIcon />}
              onClick={handleClickPlaceInventoryHold}
              kind="subtle"
              color="secondary"
              disabled={!isPlaceHoldEnabled}
              sx={{
                fontWeight: "normal"
              }}
            >
              {t("inventory hold")}
            </Button>
          </>
        )}
      </Stack>
      <ConfirmInductionButton />

      {/* Modals */}
      <InventoryHoldModal
        isOpen={isInventoryHoldModalOpen}
        onClose={() => setIsInventoryHoldModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        currentSelectedBin={currentSelectedBin}
      />
      <FlagBinModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        onSuccess={onFlagBinOrBinNotEmptySuccess}
        shouldCloseBinUponSubmit
      />
      <ChangeSuggestedBinModal
        allowCompartmentSelection={false}
        isOpen={changeSuggestedBinModalOpen}
        onClose={setChangeSuggestedBinModalOpen}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        currentSelectedBin={currentSelectedBin}
        onConfirm={onConfirmChangeSuggestedBin}
        currentSelectedCompartment={null}
        modalTitle={t("select new bin")}
        suggestBinConfigurationErrorMessage={
          suggestBinConfigurationErrorMessage
        }
        allowSelectingCompartmentsWithoutInventoryOnly={false}
      />
      {enableBinNotEmptyModal && (
        <Dialog
          onClose={() => {
            setIsBinNotEmptyPanelOpen(false);
            setNewSelectedBin(null);
            dispatch(selectNewCompartment(null));
            props.clearSelectedVariant();
          }}
          open={isBinNotEmptyPanelOpen}
          maxWidth="tablet"
        >
          {newSelectedCompartment != null && (
            <BinNotEmptyModal
              fetchNextEmptyBin={props.nextEmptyBin}
              portId={newSelectedBin?.openBinResponse.portId}
              binId={
                newSelectedBin?.autostoreBinCompartments.find(
                  (comp) =>
                    comp.autostoreCompartmentNumber ===
                    newSelectedCompartment + 1
                )?.binId
              }
              setIsBinNotEmptyPanelOpen={setIsBinNotEmptyPanelOpen}
              fetchPortStatus={props.fetchPortStatus}
              onSuccess={onFlagBinOrBinNotEmptySuccess}
            />
          )}
        </Dialog>
      )}
      <Dialog
        onClose={() => setIsDecantRateModalOpen(false)}
        open={isDecantRateModalOpen}
      >
        {selectedRow && (
          <DecantRateModal
            putAwayTask={selectedRow}
            closeModal={() => setIsDecantRateModalOpen(false)}
            successCallback={() => {
              handlePutawayTaskFetch();
            }}
          />
        )}
      </Dialog>
      {enableBinNotEmptyModal && (
        <BinNotEmptyCompartment
          isOpen={isBinNotEmptyCompartmentPanelOpen}
          setIsBinNotEmptyCompartmentPanelOpen={
            setIsBinNotEmptyCompartmentPanelOpen
          }
          setIsBinNotEmptyPanelOpen={setIsBinNotEmptyPanelOpen}
          workstation={siteWorkstation}
          nextEmptyBinByPort={nextEmptyBinByPort}
          portStateByPort={portStateByPort}
          newSelectedBin={newSelectedBin}
          setNewSelectedBin={setNewSelectedBin}
          newSelectedCompartment={newSelectedCompartment}
          setNewSelectedCompartment={(compartment) =>
            dispatch(selectNewCompartment(compartment))
          }
        />
      )}
      <ErrorSuccessSnackbar
        successMessage={successPutawayCompletion}
        errorMessage={errorPutawayCompletion}
        clearErrorMessage={() => {
          props.clearErrorMessage();
        }}
        clearSuccessMessage={() => {
          props.clearInventoryMessage();
          props.clearSuccessMessage();
        }}
      />
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={lastScannedBarcode}
        placeholderText="Scan Product"
      />
      <Dialog
        open={isUnderReceivedModalOpen}
        onClose={() => setIsUnderReceivedModalOpen(false)}
      >
        {isUnderReceivedModalOpen && selectedRow && (
          <AdjustQuantityModal
            putawayTask={selectedRow}
            closeModal={() => setIsUnderReceivedModalOpen(false)}
            handleSuccess={handleAdjustQuantityModalSuccess}
            variant="under-received"
          />
        )}
      </Dialog>
      <Dialog
        open={isOverReceivedModalOpen}
        onClose={() => setIsOverReceivedModalOpen(false)}
      >
        {isOverReceivedModalOpen && selectedRow && (
          <AdjustQuantityModal
            putawayTask={selectedRow}
            closeModal={() => setIsOverReceivedModalOpen(false)}
            handleSuccess={handleAdjustQuantityModalSuccess}
            variant="over-received"
          />
        )}
      </Dialog>
    </Container>
  );
}

export default connector(AutostorePutaway);
