import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

import ClientConfigurations from "./ClientConfigurations";
import { FcConfigList } from "./FcConfigList";
import { LaunchDarklyFlags } from "./LaunchDarklyFlags";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";

const Configurations = () => {
  const { t } = useTranslation();

  const validTabs = [
    "fc-config",
    "launch-darkly-flags",
    "client-configurations"
  ];
  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "client-configurations",
    validTabs,
    key: "configurations-tab"
  });
  return (
    <Box p={2}>
      <Tabs
        value={tabIndex}
        onChange={(_event, newValue) => {
          handleTabIndexChange(newValue as string);
        }}
      >
        <Tab
          label={`${t("client configurations")}`}
          value="client-configurations"
        />
        <Tab label={`${t("fc configuration")}`} value="fc-config" />
        <Tab
          label={`${t("Launch Darkly flags")}`}
          value="launch-darkly-flags"
        />
      </Tabs>

      <Box
        id="tab-content"
        sx={{
          height: "100%",
          width: "98%",
          overflowX: "hidden",
          overflowY: "scroll"
        }}
      >
        {tabIndex === "client-configurations" && <ClientConfigurations />}
        {tabIndex === "fc-config" && <FcConfigList />}
        {tabIndex === "launch-darkly-flags" && <LaunchDarklyFlags />}
      </Box>
    </Box>
  );
};

export default Configurations;
