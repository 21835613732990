import { Button, Collapse, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";

import { trimString } from "~/lib/helpers";
interface Props {
  text: string;
  minLength: number;
  isExpanded: boolean;
  setIsExpanded: (collapsed: boolean) => void;
}

const TypographyText = styled(Typography)(() => ({
  fontSize: "1.4em"
}));

function TextTrimmer(props: Props) {
  const { text, minLength, setIsExpanded, isExpanded } = props;

  const trimmedText = trimString(text, minLength);

  useEffect(() => {
    if (text.length > minLength) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [text, minLength]);

  return (
    <div>
      {!isExpanded ? (
        <Collapse in={!isExpanded}>
          <TypographyText role="heading">{trimmedText}</TypographyText>
        </Collapse>
      ) : (
        <TypographyText role="heading">{text}</TypographyText>
      )}

      {text.length > minLength && (
        <Button
          sx={{ color: "#fff", backgroundColor: "darkGray.main" }}
          variant="contained"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isExpanded ? "Show More" : "Show Less"}
        </Button>
      )}
    </div>
  );
}
export default TextTrimmer;
