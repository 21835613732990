import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useAppSelector } from "~/app/store";
import { activityStateFromPortResponse } from "~/components/autostore/autostoreBin/ActivityState";
import { GetPortResponse } from "~/redux/actions/autostore";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import {
  useGetBinQuery,
  useGetPortStatusQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

import { useShouldListenToGridEvents } from "./useShouldListenToGridEvents";

/**
 * @deprecated see usePortStatusQuery for the preferred usage
 */
const usePortStatus = (
  portStateByPort: {
    [portId: number]: {
      getPortResponse: GetPortResponse;
      timestamp: Date;
    };
  },
  siteAllPortIds: number[],
  currentSelectedPortId?: number | undefined
) => {
  return useMemo(() => {
    const portStateByPortArray = Object.values(portStateByPort);

    const areAllPortsReady =
      !!portStateByPortArray.length &&
      siteAllPortIds.length === portStateByPortArray.length &&
      portStateByPortArray.every((port) => port.getPortResponse.isReady);
    const areAllPortsOpen =
      !!portStateByPortArray.length &&
      portStateByPortArray.every(
        (port) => port.getPortResponse.mode === "OPEN"
      );

    const areSomePortsReady =
      !!portStateByPortArray.length &&
      portStateByPortArray.some((port) => port.getPortResponse.isReady);

    const areTwoOrMorePortsReady =
      !!portStateByPortArray.length &&
      portStateByPortArray.filter((port) => port.getPortResponse.isReady)
        .length >= 2;

    const isSelectedBinReady = currentSelectedPortId
      ? portStateByPort[currentSelectedPortId]?.getPortResponse.isReady &&
        portStateByPort[currentSelectedPortId]?.getPortResponse.mode === "OPEN"
      : false;

    const firstPort = portStateByPortArray.length
      ? portStateByPortArray[0]
      : null;

    return {
      portStateByPortArray,
      areAllPortsReady,
      areAllPortsOpen,
      areSomePortsReady,
      areTwoOrMorePortsReady,
      isSelectedBinReady,
      firstPort
    };
  }, [currentSelectedPortId, portStateByPort, siteAllPortIds.length]);
};

export default usePortStatus;

export const usePortStatusQuery = (portId: number, portSideIndex?: number) => {
  const workstation = useAppSelector(selectThisWorkstation);
  const shouldListenToGridEvents = useShouldListenToGridEvents();

  const [isWaitingForBin, setIsWaitingForBin] = useState(false);
  const pollingIntervalWhenWaiting = shouldListenToGridEvents ? 7000 : 500;
  const pollingInterval = isWaitingForBin
    ? pollingIntervalWhenWaiting
    : undefined;

  const {
    data: portStatus,
    error: portStatusError,
    refetch: refetchPortStatus
  } = useGetPortStatusQuery(
    workstation
      ? {
          autostoreGridId: workstation.autostoreGridId,
          portId
        }
      : skipToken,
    {
      pollingInterval,
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, error }) => ({
        data,
        error
      })
    }
  );

  const {
    data: binState,
    error: binStateError,
    isUninitialized: binStateUninitialized,
    refetch: refetchBinState
  } = useGetBinQuery(
    portStatus?.selectedBin && workstation
      ? {
          autostoreGridId: workstation.autostoreGridId,
          binNumber: portStatus?.selectedBin
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data, error, isUninitialized }) => ({
        data,
        error,
        isUninitialized
      })
    }
  );

  const binPortSideIndex =
    workstation?.multiPortEnabled && binState?.portSide === "right" ? 1 : 0;

  // fail-safe in case we don't miss the events from signalr
  // poll every 7 seconds to get latest state
  useEffect(() => {
    setIsWaitingForBin(
      portStatus?.mode !== "CLOSED" && portStatus?.isReady === false
    );
  }, [portStatus]);

  const refetch = useCallback(async () => {
    await refetchPortStatus();
    if (!binStateUninitialized) {
      await refetchBinState();
    }
  }, [refetchPortStatus, refetchBinState, binStateUninitialized]);

  return {
    ...portStatus,
    ...binState?.binConfiguration,
    binPortSideIndex,
    activityState: activityStateFromPortResponse(
      portStatus,
      typeof portSideIndex === "number"
        ? portSideIndex === binPortSideIndex
        : undefined
    ),
    error: portStatusError ?? binStateError,
    refetch,
    binState
  };
};
