import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useAppSelector } from "~/app/store";
import { AutostoreInactivityTimerModal } from "~/components/autostore/inactivity/AutostoreInactivityTimerModal";

import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

const defaultTimeoutAfterModalSeconds = 30;

export const AutostoreInactivityResetTimerContext = createContext<{
  setPageSpecificReset: (
    pageSpecificReset?: () => Promise<void> | void
  ) => void;
  setIsEnabled: (isEnabled: boolean) => void;
  restartInactivityTimer: () => void;
  clearInactivityTimer: () => void;
}>(null as never);

type InactivityResetTimerProps = {
  onInactivityModalClose?: () => Promise<void> | void;
};

export const useInactivityResetTimer = ({
  onInactivityModalClose
}: InactivityResetTimerProps) => {
  const {
    setIsEnabled,
    setPageSpecificReset,
    clearInactivityTimer,
    restartInactivityTimer
  } = useContext(AutostoreInactivityResetTimerContext);

  useEffect(() => {
    setIsEnabled(true);
    setPageSpecificReset(onInactivityModalClose);

    return () => {
      setIsEnabled(false);
      setPageSpecificReset();
    };
  }, [onInactivityModalClose]);

  return useMemo(
    () => ({ clearInactivityTimer, restartInactivityTimer }),
    [clearInactivityTimer, restartInactivityTimer]
  );
};

export const AutostoreInactivityResetTimerProvider = ({
  children
}: PropsWithChildren) => {
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [isInactivityTimerModalOpen, setIsInactivityTimerModalOpen] =
    useState(false);
  const [pageSpecificResetValue, setPageSpecificResetValue] = useState<
    (() => Promise<void> | void) | undefined
  >();
  const [isEnabled, setIsEnabled] = useState(false);

  // react needs a function that returns the function here due to lazy set state
  const setPageSpecificReset = useCallback(
    (pageSpecificReset?: () => Promise<void> | void) => {
      setPageSpecificResetValue(() => pageSpecificReset);
    },
    []
  );

  const restartInactivityTimer = useCallback(() => {
    setIsInactivityTimerModalOpen(false);

    if (!isEnabled || !fulfillmentCenter?.binAtPortAutoCloseSeconds) return;

    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    inactivityTimerRef.current = setTimeout(
      () => {
        setIsInactivityTimerModalOpen(true);
      },
      Math.abs(
        fulfillmentCenter?.binAtPortAutoCloseSeconds -
          defaultTimeoutAfterModalSeconds
      ) * 1000
    );
  }, [fulfillmentCenter?.binAtPortAutoCloseSeconds, isEnabled]);

  const clearInactivityTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = null;
    }
  }, [inactivityTimerRef]);

  useEffect(() => {
    if (!isEnabled || !fulfillmentCenter?.binAtPortAutoCloseSeconds) {
      window.removeEventListener("click", restartInactivityTimer);
      clearInactivityTimer();
      return;
    }

    window.addEventListener("click", restartInactivityTimer);
    restartInactivityTimer();

    return () => {
      window.removeEventListener("click", restartInactivityTimer);
      clearInactivityTimer();
    };
  }, [
    clearInactivityTimer,
    fulfillmentCenter?.binAtPortAutoCloseSeconds,
    isEnabled,
    restartInactivityTimer
  ]);

  const context = useMemo(
    () => ({
      setIsEnabled,
      setPageSpecificReset,
      clearInactivityTimer,
      restartInactivityTimer
    }),
    [clearInactivityTimer, restartInactivityTimer]
  );

  const childrenMemo = useMemo(() => children, [children]);

  return (
    <AutostoreInactivityResetTimerContext.Provider value={context}>
      {childrenMemo}
      {isEnabled &&
        fulfillmentCenter?.binAtPortAutoCloseSeconds &&
        isInactivityTimerModalOpen && (
          <AutostoreInactivityTimerModal
            open={isInactivityTimerModalOpen}
            resetTimerCallback={restartInactivityTimer}
            pageSpecificReset={pageSpecificResetValue}
            timeLeft={defaultTimeoutAfterModalSeconds}
          />
        )}
    </AutostoreInactivityResetTimerContext.Provider>
  );
};
